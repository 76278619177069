import {Environment} from "./environment.interface";

export const environment: Environment = {
  production: false,
  authToken: '9902dcfd045723d9479ea310c08a8cb6e4252dfc',
  clientId: 'logistics',
  authServerUrl: 'https://keycloak-beige.12stz.com/auth/',
  backend: 'https://logistics.12stz.dev/api/v1',
  geoApi: 'https://logistics-bff-ms.12stz.dev',
  sentryDSN: 'https://1c76a930601b44fb89cb67a3aaa39508@sentry.12stz.dev/5',
  name: 'dev',
};
